<template>
  <div class="container-menu">
    <item route="Dashboard" name="Dashboard" icon="dashboard">Dashboard</item>
    <item route="Pedidos" name="Pedidos" icon="products" />
    <item route="Integrações" name="Integrações" icon="links" />
    <!-- <item route="Statements" name="Extrato" icon="extrato" />
    <item route="Coupon" name="Cupom" icon="cupom" />
    <item route="NoteCenter" name="Central de Notas" icon="notas" />
    <item route="Checkouts" name="Checkouts" icon="grid" />
    <item route="Affiliation" name="Afiliações" icon="affiliate" /> -->
    <!-- <item route="MyAccount" name="Conta" icon="user-config" /> -->
    <!-- <item route="Settings" name="Sistema" icon="company-config" /> -->
    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/icons/sign-out.svg" />
        Deslogar
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";

export default {
  components: {
    Item,
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  /* height: 80vh; */
  overflow: auto;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>