<template>
  <div class="container-logout">
    <div class="logout-btn">
      <router-link to="/minha-conta">
        <img
          :src="foto_usuario"
          class="foto-usuario"
          :class="{ contains: foto_usuario }"
        />
      </router-link>
      <!-- <div class="foto-usuario"></div> -->

      <div class="space-flex">
        <div class="infos-user">
          <!-- <p>{{ $store.state.user.user.name}}</p> -->
          <p>{{ userName }}</p>
          <!-- <span>Nome do Projeto</span> -->
        </div>

        <a @click.prevent="logout">
          <img src="@/assets/icons/sign-out.svg" class="item-config" />
        </a>

        <ul class="sub-menu" id="sub_config" v-show="sub_menu">
          <li @click="sub_menu = false">
            <router-link to="/minha-conta">
              <img src="@/assets/icons/user-config.svg" />
              Minha conta
            </router-link>

            <router-link to="/configuracoes">
              <img src="@/assets/icons/company-config.svg" />
              Configurações do sistema
            </router-link>
            <a href="#" id="logout-btn" @click.prevent="logout"
              ><img src="@/assets/icons/sign-out.svg" /> Finalizar sessão</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name_user: "",
      sub_menu: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
    };
  },
  computed: {
    foto_usuario() {
      if (this.$store.getters.isMember) {
        var dados = JSON.parse(this.$store.getters.isMember);
        return dados.image;
      } else return null;
    },
    userName() {
      if (this.$store.getters.isMember) {
         var dados = JSON.parse(this.$store.getters.isMember);
        // return this.$store.state.user.user.name.replace(/ .*/, "");
        if (dados.name) {
          const name = dados.name.split(" ");
          if (name.length > 1) {
            return `${name[0]} ${name[1]}`;
          } else {
            return name[0];
          }
        } else return "";
      } else return "";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch((err) => {});
    },
    minha_conta() {
      this.$router.push("/minha-conta");
    },
    menuConfig(e) {
      this.item = e.currentTarget.parentElement.parentElement.parentElement;
      this.top = this.item.offsetTop - 120;
      const submenu = document.querySelector("#sub_config");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft + 9;
      this.sub_menu = !this.sub_menu;
    },
    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
        }
      });
    },
  },
  mounted() {
    this.eventMouse();
  },
};
</script>
<style lang="scss" scoped>
.container-logout {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
}
.container-logout::before {
  content: "";
  width: 300px;
  height: 1px;
  background: #ededf0;
  display: block;
  margin-left: -40px;
  margin-bottom: 20px;
}

// novo rodape
.logout-btn {
  width: 100%;
  display: flex;
  margin-left: -8px;
}
.foto-usuario {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ededf0;
  margin-right: 20px;
  object-fit: contain;
}
.foto-usuario.contains {
  background: none;
}

.space-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infos-user {
  display: block;
  margin-right: 25px;
  width: 110px;
}
.infos-user p {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}
.infos-user span {
  font-size: 12px;
}
.space-flex img.item-config {
  filter: invert(50%);
  width: 20px;
  cursor: pointer;
  transition: all .3s;
}

.space-flex img.item-config:hover {
  filter: invert(0%);
}

.sub-menu a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}

.sub-menu a img {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 35px;
}
.sub-menu a:hover img {
  filter: invert(0);
}

img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}

/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 270px;
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_config {
  width: 310px;
}
.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;

  /* background: silver; */
}
.sub-menu li a:hover {
  text-decoration: none;
  color: var( --basecolor);
}
.sub-menu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}

.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
#logout-btn:hover {
  color: #ff0c37;
}
</style>