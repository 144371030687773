<template>
  <span id="SiteTemplate" class="mb-5" :class="{'grid-base':!isMobile, 'grid-mobile':isMobile }">
    <MinSidebar v-if="isMobile" />
    <MaxSidebar v-if="!isMobile" />

    <div class="container">
      <b-row>
        <GridColum :tamanho="12" :mt="5">
          <slot></slot>
        </GridColum>
      </b-row>
    </div>
  </span>
</template>

<script>
import GridColum from "@/components/base/GridColum.vue";
import MaxSidebar from "@/components/sidebar/index";
import MinSidebar from "@/components/sidebar/Toggle-sidebar/index";

export default {
  name: "SiteTemplate",
  components: {
    GridColum,
    MaxSidebar,
    MinSidebar,
  },
  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  created() {
    if (this.detectOs() == "Android") {
      window.screen.orientation.lock("portrait");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

  },
  methods: {
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "../assets/scss/main.scss";
</style>
