<template>
  <div class="menu-relative">
    <div class="Menu logo-close">
      <span class="Menu-logo mb-4">
        <router-link to="/" class="after-element"
          >
          <Logo class="ml-logo mb-4 mt-4" />
          </router-link>
      </span>
      <Menu />
    </div>
  </div>
</template>
<script>
import Menu from "./Menu";
import Logo from "@/components/base/Logo.vue";

export default {
  data() {
    return {
      srcLogo: "",
    };
  },
  components: {
    Menu,
    Logo
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
    minimizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
    logo() {
      this.srcLogo = require("@/assets/logo-collapsed.png");
      const menu = document.querySelector(".Menu");

      menu.addEventListener("mouseenter", () => {
        menu.classList.remove("logo-close");
      });
      menu.addEventListener("mouseleave", () => {
        menu.classList.add("logo-close");
      });
    },
  },
  mounted() {
    this.logo();
  },
};
</script>

<style scoped>
.menu-relative {
  position: relative;
  width: 100px;
  /* height: 100vh; */
}
.Menu {
  padding: 20px 40px;
  background: #ffffff;
  border: 1px solid #ededf0;
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: none;
  margin: -1px;
  max-width: 100px;
  height: 101vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s;
  z-index: 1000 !important;
  overflow: hidden;
}
.Menu:hover {
  max-width: 300px;
}

.Menu-logo img {
  margin: 30px auto 20px;
  max-width: 100px;
  height: auto;
  transition: 0.3s;
}

.ml-logo{
  margin-left: -15px;
}
</style>